<template>
  <v-container
    fluid
    tag="section"
  >

<base-material-card
      icon="mdi-check"
      title="Contratos Aprovados"
      class="px-5 py-3"
    >


   <v-dialog 
      title="Mensagem" 

    >

    <v-container >
      <c-row class="justify-content-center">
              <v-form>
                  {{ msg_dialog }}

              </v-form>
      </c-row>
    </v-container>  

    </v-dialog>

      <v-dialog
        v-model="dialog"
        width="500"
      >

        <v-card>
            <v-toolbar  text
                color="success"
                   dark > 
                <h3> Mensagem</h3>
             </v-toolbar>



          <v-card-text>
            {{ msg_dialog }}
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-simple-table>
        <thead>
          <tr>
          <th style="width: 20%;font-size:14px;font-weight: bold;">
              Nome
            </th>
          <th style="width: 20%;font-size:14px;font-weight: bold;">
              Plano
            </th>
 
          <th style="width: 10%;font-size:14px;font-weight: bold;">
              Data Aprovação
            </th>
          <th style="width: 15%;font-size:14px;font-weight: bold;">
              Usuário
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="item in contratos"
            :key="item.id"
          >
            <td>{{ item.nome }}</td>
            <td>{{ item.nome_plano }}</td>
            <td>{{ item.dataStatus }}</td>
            <td>{{ item.userStatus }}</td>
            <td style="width: 45%;">

            <v-btn color="warning" @click="download_pdf(item.id)" :loading="loading3" small>
                  Ficha
               <v-icon right dark>mdi-cloud-download</v-icon>      
            </v-btn>
            <v-btn style="margin-left:10px" color="danger" @click="download_zip(item.id)" :loading="loading3" small>Anexo
                <v-icon right dark>mdi-cloud-download</v-icon>      
            </v-btn>

            </td>
          </tr>
        </tbody>
      </v-simple-table>
  </base-material-card>
  </v-container>
</template>
<script>
  import Contrato from '../../../services/contrato'
  import axios from 'axios'
  import authHeader from '../../../services/auth-header';
  import config  from '../../../services/config'


  export default {


     created() {
          if(this.$store.state.auth.status.loggedIn == false){
            this.$router.push('/')
          }
      },

    data: () => ({
      contratos: [],
      msg_dialog: '',
      dialog: false,
      loading3: false,
    }),

    mounted () {
      this.listar()
    },

    methods: {
      listar () {
        Contrato.lista('Previda', 'APROVADO').then(response => {
            this.contratos = response.data
        }).catch(e => {
            if(e.response.status === 401){
            this.$store.dispatch('auth/logout');
            this.$router.push('/restrito/login');
            }

        }).finally(() => {

        })
      },

      aprovar(id){
            Contrato.update(id).then(response => {
            alert(response.data);
        }).catch(e => {
           
        }).finally(() => {

        })
      },

      download_pdf (id) {
       this.loading3 = true;
        axios({
          url: config.url + 'download_ficha?id=' + id,
          method: 'GET',
          headers: authHeader(),
          responseType: 'blob',
        }).then((response) => {
            this.loading3 = false;

          if (response.status === 200) {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', 'ficha_' + id + '.pdf')
            document.body.appendChild(fileLink)
            fileLink.click()

          } else {
            this.dialog = true
            this.msg_dialog = 'Cliente não tem documentos'
          }
        }).catch(() => {
          this.dialog = true
          this.loading3 = false;
          this.msg_dialog = 'Problemas de Comunicação com o servidor, tente novamente mais tarde ou entre em contato com o Suporte'
        })
      },
      download_zip (id) {
    
      this.loading3 = true;

        axios({          
          url: config.url + 'download_zip?id=' + id,
          method: 'GET',
          headers: authHeader(),
          responseType: 'blob',
        }).then((response) => {
           
           this.loading3 = false;

          if (response.status === 200) {
            if (response.data.size > 1) {
                  const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                  const fileLink = document.createElement('a')
                  fileLink.href = fileURL
                  fileLink.setAttribute('download', 'docs_' + id + '.zip')
                  document.body.appendChild(fileLink)
                  fileLink.click()
              }else{
                  this.dialog = true
                  this.status_msg = 'danger'
                  this.msg_dialog = 'Não existe documento anexo ao contrato'
          
              }
          } else {
            this.dialog = true
            this.msg_dialog = 'Problemas de Comunicação com o servidor, tente novamente mais tarde ou entre em contato com o Suporte'
          }
        }).catch(() => {
          
          this.loading3 = false;
          this.dialog = true
          this.msg_dialog = 'Problemas de Comunicação com o servidor, tente novamente mais tarde ou entre em contato com o Suporte'
        })
      },
    },
  }

</script>
